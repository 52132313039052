import React, {useEffect, useState} from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import {Link} from 'react-scroll'
import {SRLWrapper} from 'simple-react-lightbox'
import Layout from './../components/layout'
import Container from './../components/container'
// import {stripMarkup} from './../helper-functions/strip-markup'
// import BodyCopy from './../components/body-copy'
import DownArrowSvg from './../components/down-arrow-svg'
import ScrollToTop from './../components/scroll-to-top'
import {HOME_PAGE_SCROLL_DOWN_DURATION_MS, SIMPLE_REACT_LIGHTBOX_OPTIONS_NO_NAV} from './../constants/constants'

export default props => {
  let [homepageHeroImageIndex, setHomepageHeroImageIndex] = useState(0)

  const {data} = props
  const prismicData = {
    heroHeadingText: data.prismicHomePage.data.hero_heading.text,
    heroSubHeadingText: data.prismicHomePage.data.hero_subheading.text,
    shortIntroText1: data.prismicHomePage.data.short_intro_text_1.html,
    bodyCopy1: data.prismicHomePage.data.body_copy_1.html,
    bodyCopySuffix1: data.prismicHomePage.data.body_copy_suffix_1.html,
    youTubeVideoId: data.prismicHomePage.data.youtube_video_id.text,
    youTubeTitleText: data.prismicHomePage.data.youtube_title_text.text,
    shortIntroText2: data.prismicHomePage.data.short_intro_text_2.html,
    bodyCopy2: data.prismicHomePage.data.body_copy_2.html,
    bodyCopySuffix2: data.prismicHomePage.data.body_copy_suffix_2.html,
    bodyCopy2imageUrl: data.prismicHomePage.data.body_copy_2_image.url,
    bodyCopy2imageAlt: data.prismicHomePage.data.body_copy_2_image.alt,
    bodyCopy2imageTitle: data.prismicHomePage.data.body_copy_2_image_title.html,
    backgroundAttachmentFixed: data.prismicHomePage.data.background_attachment_fixed,
    heroImages: data.prismicHomePage.data.hero_images,
  }

  useEffect(() => {
    let localStorageValue = localStorage.getItem('homepageHeroImageIndex')
    if (localStorageValue) {
      localStorageValue = parseInt(localStorageValue, 10) + 1
      if (localStorageValue > prismicData.heroImages.length - 1) {
        localStorageValue = 0
      }
      setHomepageHeroImageIndex(localStorageValue)
      localStorage.setItem('homepageHeroImageIndex', localStorageValue)
    } else {
      setHomepageHeroImageIndex(1)
      localStorage.setItem('homepageHeroImageIndex', '0')
    }
  }, [])

  let backgroundPosition
  if (prismicData.heroImages[homepageHeroImageIndex].background_position.toLowerCase() === 'top') {
    backgroundPosition = 'center top'
  } else if (prismicData.heroImages[homepageHeroImageIndex].background_position.toLowerCase() === 'middle') {
    backgroundPosition = 'center center'
  } else if (prismicData.heroImages[homepageHeroImageIndex].background_position.toLowerCase() === 'bottom') {
    backgroundPosition = 'center bottom'
  }

  const heroDesktopStyle = {
    backgroundImage: `url('${prismicData.heroImages[homepageHeroImageIndex].hero_image_desktop.url}')`,
  }

  const heroTabletStyle = {
    backgroundImage: `url('${prismicData.heroImages[homepageHeroImageIndex].hero_image_tablet.url}')`,
    backgroundPosition: backgroundPosition,
  }

  const heroMobileStyle = {
    backgroundImage: `url('${prismicData.heroImages[homepageHeroImageIndex].hero_image_mobile.url}')`,
    backgroundPosition: backgroundPosition,
  }

  return (
    <Layout pageName="index" documentTitlePrefix="">
      <Fade>
        <StyledHeroImage
          className={`hero-image hero-image--${homepageHeroImageIndex}`}
          backgroundAttachmentFixed={prismicData.backgroundAttachmentFixed}
        >
          <div className="hero-inner">
            <div className="hero-inner-bg hero-inner-bg--desktop" style={heroDesktopStyle} />
            <div className="hero-inner-bg hero-inner-bg--tablet" style={heroTabletStyle} />
            <div className="hero-inner-bg hero-inner-bg--mobile" style={heroMobileStyle} />
            <Fade delay={600}>
              <div className="hero-heading-wrapper">
                <Fade delay={1200}>
                  <h1 id="hero-heading" className="hero-heading">
                    {prismicData.heroHeadingText}
                  </h1>
                  <h2 id="hero-subheading" className="hero-subheading">
                    {prismicData.heroSubHeadingText}
                  </h2>
                </Fade>
              </div>
            </Fade>
            <div className="scroll-btn-wrapper">
              <Fade delay={1800}>
                <Link
                  to="home-page-body-text-wrapper"
                  className="scroll-btn scroll-btn--mobile"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={HOME_PAGE_SCROLL_DOWN_DURATION_MS}
                >
                  <span className="sr-only">Scroll down</span>
                  <DownArrowSvg className="down-arrow-svg" fill="#fff" />
                </Link>
                <Link
                  to="home-page-body-text-wrapper"
                  className="scroll-btn scroll-btn--desktop"
                  spy={true}
                  smooth={true}
                  offset={-90}
                  duration={HOME_PAGE_SCROLL_DOWN_DURATION_MS}
                >
                  <span className="sr-only">Scroll down</span>
                  <DownArrowSvg className="down-arrow-svg" fill="#fff" />
                </Link>
              </Fade>
            </div>
          </div>
        </StyledHeroImage>
      </Fade>
      <Container id="home-page-body-text-wrapper" className="home-page-body-text-wrapper">
        <StyledBodyTextWrapper>
          <div className="row row--1">
            <div className="col col--left col--body-1">
              <Fade>
                <div className="short-intro-text-1" dangerouslySetInnerHTML={{__html: prismicData.shortIntroText1}} />
                <div className="body-copy-1" dangerouslySetInnerHTML={{__html: prismicData.bodyCopy1}} />
                <div className="body-copy-suffix-1" dangerouslySetInnerHTML={{__html: prismicData.bodyCopySuffix1}} />
              </Fade>
            </div>
            <div className="col col--right col--profile">
              <Fade>
                <h3 className="profile__heading">Profile</h3>
                <div className="profile__body">
                  <ul>
                    <li>Frederick Cuming (1930-2022)</li>
                    <li>Royal Academician</li>
                    <li>Painter</li>
                    <li>Nationality: British</li>
                    <li>Elected ARA: 25th April 1969</li>
                    <li>Elected RA: 12th February 1974</li>
                    <li>Elected Senior RA: 1st October 2005</li>
                    <li>Born: 16th February 1930 in London, England</li>
                    <li>Died: 12th June 2022 in Iden, East Sussex</li>
                  </ul>
                </div>
              </Fade>
            </div>
          </div>

          <div className="row row--2">
            <div className="col col--left col--video">
              <Fade>
                <div className="embed-container">
                  <iframe
                    title="YouTube video"
                    src={`https://www.youtube-nocookie.com/embed/${prismicData.youTubeVideoId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                    allowFullScreen
                  />
                </div>
                <p className="youtube-title-text">{prismicData.youTubeTitleText}</p>
              </Fade>
            </div>
            <div className="col col--right col--blockquote">
              <Fade>
                <blockquote>
                  <p className="para para--1">Art to me is a form of abstraction.</p>
                  <p className="para para--2">
                    It&rsquo;s the artist&rsquo;s job to convey the message, meaning and emotion which is the essence of
                    abstraction – what you have experienced as a person and how you interpret it into your work.
                  </p>
                  <p className="para para--3">What is an artist for?</p>
                  <p className="para para--4">Surely not just to record what he sees, but to interpret what he sees.</p>
                  <p className="para para--5">We are all searching.</p>
                  <footer>Fred Cuming, RA</footer>
                </blockquote>
              </Fade>
            </div>
          </div>

          <div className="row row--3">
            <div className="col col--left col--body-2">
              <Fade>
                <div className="short-intro-text-2" dangerouslySetInnerHTML={{__html: prismicData.shortIntroText2}} />
                <div className="body-copy-2" dangerouslySetInnerHTML={{__html: prismicData.bodyCopy2}} />
                <div className="body-copy-suffix-2" dangerouslySetInnerHTML={{__html: prismicData.bodyCopySuffix2}} />
              </Fade>
            </div>
            <div className="col col--right col--image">
              <SRLWrapper options={SIMPLE_REACT_LIGHTBOX_OPTIONS_NO_NAV}>
                <Fade>
                  <figure>
                    <img src={prismicData.bodyCopy2imageUrl} alt={prismicData.bodyCopy2imageAlt} />
                    <figcaption dangerouslySetInnerHTML={{__html: prismicData.bodyCopy2imageTitle}} />
                  </figure>
                </Fade>
              </SRLWrapper>
            </div>
          </div>
        </StyledBodyTextWrapper>
      </Container>
      <ScrollToTop />
    </Layout>
  )
}

const StyledHeroImage = styled.div`
  align-items: center;

  display: flex;
  flex-direction: column;
  height: 0;
  justify-content: center;
  overflow: hidden;
  /* padding-top: 56.25%; */
  padding-top: 49.4%;
  position: relative;

  @media (max-width: 900px) {
    height: calc(100vh - 7.6rem);
    padding-top: 0;
  }

  .hero-inner {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .hero-inner-bg {
    background-attachment: ${props => (props.backgroundAttachmentFixed ? 'fixed' : 'scroll')};
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &--desktop {
      display: none;
      background-position: center bottom;

      @media (min-width: 900px) {
        display: block;
      }
    }

    &--tablet {
      display: none;
      background-position: center center;

      @media (min-width: 600px) {
        display: block;
      }

      @media (min-width: 900px) {
        display: none;
      }
    }

    &--mobile {
      display: block;
      background-position: center center;

      @media (min-width: 600px) {
        display: none;
      }
    }
  }

  .hero-heading-wrapper {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.33);
    display: flex;
    flex-direction: column;
    height: 16rem;
    justify-content: center;
    margin-top: -8rem;
    position: absolute;
    top: 50%;
    width: 100%;
  }

  .hero-heading {
    color: #fff;
    font-size: 9vw;
    font-weight: 200;
    line-height: 1;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    transform: translateY(2px);

    /* @media (min-width: 600px) {
      font-size: 7vw;
    } */

    @media (min-width: 622px) {
      font-size: 5.6rem;
    }
  }

  .hero-subheading {
    color: #fff;
    font-size: 4.3vw;
    margin-bottom: 0;
    margin-top: 1.2rem;

    @media (min-width: 400px) {
      font-size: 1.8rem;
    }
  }

  .scroll-btn-wrapper {
    animation: scrollDownArrowAnimation 4s linear 0s infinite normal none;
    bottom: 8.5rem;
    display: block;
    height: auto;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 5.5rem;

    @media (min-width: 768px) {
      bottom: 8.5rem;
    }

    @media (min-width: 900px) {
      bottom: 5rem;
    }
  }

  .scroll-btn {
    color: #fff;
    cursor: pointer;
    height: auto;
    width: 5.5rem;

    @media (min-width: 800px) {
      width: 5.5rem;
    }

    @media (min-width: 1000px) {
      width: 5.5rem;
    }

    &--mobile {
      display: block;

      @media (min-width: 1200px) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @media (min-width: 1200px) {
        display: block;
      }
    }
  }
`

const StyledBodyTextWrapper = styled.div`
  .row {
    display: flex;
    flex-direction: row;
    padding-top: 9rem;

    @media (max-width: 900px) {
      flex-direction: column;
    }

    &--1 {
      padding-top: 6rem;
    }

    &.row--3 {
      /* margin-bottom: 2rem; */

      @media (min-width: 1200px) {
        margin-bottom: 9rem;
      }

      @media (max-width: 900px) {
        flex-direction: column-reverse;
      }

      .col--left {
        max-width: 62.6rem;

        @media (max-width: 900px) {
          margin-bottom: 0;
          max-width: none;
        }
      }

      .col--right {
        @media (max-width: 900px) {
          margin-bottom: 9rem;
        }
      }
    }

    .col {
      &--left {
      }
    }
  }

  .col {
    &--left {
      margin-right: 11.5rem;
      max-width: 83rem;
      padding-left: 6.3rem;
      width: 100%;

      @media (max-width: 1050px) {
        margin-right: 5rem;
        padding-left: 2rem;
      }

      @media (max-width: 900px) {
        margin-right: 0;
        padding-right: 2rem;
        margin-bottom: 9rem;
      }

      @media (max-width: 570px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &--right {
      @media (max-width: 900px) {
        margin-right: 0;
        padding-left: 2rem;
        padding-right: 2rem;
      }

      @media (max-width: 570px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &--profile {
      flex-shrink: 0;
      max-width: 30rem;
      min-width: 30rem;
      width: 30rem;

      @media (max-width: 1364px) {
        min-width: 32.3rem;
        width: 32.3rem;
      }

      @media (max-width: 900px) {
        max-width: none;
        min-width: 0;
        width: 100%;
      }

      @media (max-width: 700px) {
        font-size: 1.5rem;
        min-width: 0;
      }

      @media (max-width: 630px) {
        font-size: 1.4rem;
        min-width: 0;
      }

      @media (max-width: 600px) {
        font-size: 1.3rem;
        min-width: 0;
      }

      @media (max-width: 570px) {
        font-size: 1.7rem;
      }

      @media (max-width: 350px) {
        font-size: 1.6rem;
      }

      @media (max-width: 320px) {
        font-size: 1.5rem;
      }
    }
    &--blockquote {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      min-width: 32.3rem;

      @media (max-width: 900px) {
        text-align: justify;
      }

      @media (max-width: 570px) {
        text-align: left;
        min-width: 0;
      }
    }
    &--image {
      min-width: 32.3rem;

      @media (max-width: 900px) {
        min-width: 0;
      }
    }
  }

  .short-intro-text-1,
  .body-copy-1,
  .body-copy-suffix-1,
  .short-intro-text-2,
  .body-copy-2,
  .body-copy-suffix-2 {
    text-align: justify;
    text-justify: inter-word;

    @media (max-width: 570px) {
      text-align: left;
    }
  }

  .short-intro-text-1,
  .short-intro-text-2 {
    font-weight: 400;
    font-size: 2.5rem;
    margin-bottom: 1.9rem;
    position: relative;

    &::before {
      content: '“';
      display: block;
      font-size: 6.4rem;
      left: -3.2rem;
      position: absolute;
      top: -2.3rem;
      transform: scaleY(0.8);

      @media (max-width: 570px) {
        font-size: 4.9rem;
        left: -1.8rem;
        top: -3.3rem;
      }
    }

    p {
      line-height: 1.2;
    }
  }

  .body-copy-1,
  .body-copy-2 {
    font-size: 1.7rem;
    font-weight: 100;
    margin-bottom: 2.8rem;

    p {
      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: 0;

        &::after {
          content: '”';
        }
      }
    }
  }

  .profile {
    &__heading {
      font-size: 2.4rem;
      margin-bottom: 1.5rem;
      text-transform: uppercase;
    }
    &__body {
      font-weight: 600;
      padding: 3rem 0;
      position: relative;

      &::before,
      &::after {
        content: '';
        display: block;
        background-color: #909090;
        width: 10.3rem;
        height: 2px;
        position: absolute;
        left: 0;
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }

      ul {
        font-size: 1.3rem;
        list-style: none;
        margin: 0;
        padding: 0;

        @media (max-width: 900px) {
          display: grid;
          grid-template-rows: repeat(5, 1fr);
          grid-auto-flow: column;
          grid-auto-columns: 50%;
          grid-row-gap: 0;
          grid-column-gap: 1rem;
        }

        @media (max-width: 570px) {
          display: flex;
          flex-direction: column;
        }
      }
      li {
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .youtube-title-text {
    font-weight: 600;
    padding-left: 1rem;
    margin-top: 2rem;
  }

  blockquote {
    margin: 0;
    max-width: 43rem;

    @media (max-width: 900px) {
      max-width: none;
    }

    .para {
      font-weight: 100;
      font-size: 1.7rem;
      font-style: normal;
      margin-bottom: 2.1rem;

      &--1 {
        font-size: 2.5rem;
        font-weight: 400;
        line-height: 1.2;
        position: relative;

        &::before {
          content: '“';
          font-size: 6.4rem;
          left: -3.2rem;
          position: absolute;
          top: -2.3rem;
          transform: scaleY(0.8);

          @media (max-width: 570px) {
            font-size: 4.9rem;
            left: -1.8rem;
            top: -3.3rem;
          }
        }
      }

      &--5 {
        margin-bottom: 3.1rem;

        &::after {
          content: '”';
        }
      }
    }

    footer {
      font-weight: 500;
    }
  }

  figure {
    margin: 0;

    img {
      cursor: pointer;
      height: auto;
      margin-bottom: 0.6rem;
      max-width: 51.8rem;
      width: 100%;

      @media (max-width: 900px) {
        max-width: none;
      }
    }

    figcaption {
      text-align: center;
      font-size: 1.4rem;
      font-weight: 100;

      p {
        margin-bottom: 0;

        &:last-child {
          color: #585858;
        }
      }
    }
  }

  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    prismicHomePage {
      data {
        hero_heading {
          text
        }
        hero_subheading {
          text
        }
        short_intro_text_1 {
          html
        }
        body_copy_1 {
          html
        }
        background_attachment_fixed
        body_copy_2 {
          html
        }
        body_copy_2_image {
          url
          alt
        }
        body_copy_2_image_title {
          html
        }
        body_copy_suffix_1 {
          html
        }
        body_copy_suffix_2 {
          html
        }
        short_intro_text_2 {
          html
        }
        youtube_title_text {
          text
        }
        youtube_video_id {
          text
        }
        hero_images {
          hero_image_desktop {
            url
          }
          hero_image_mobile {
            url
          }
          hero_image_tablet {
            url
          }
          background_position
        }
      }
    }
  }
`
